import { FC, useState } from "react";

import FileInput from "../../components/file_input/file_input";
import Checkbox from "../../components/checkbox/checkbox";

import woman_drink from '../../assets/img/konkurs_img.png'
import trophy from '../../assets/img/trophy_circles.png'
import awards from '../../assets/img/awards.png'
import awards_bubbles from '../../assets/img/awards_bubbles.png'
import num1 from '../../assets/img/num1.png'
import num2 from '../../assets/img/num2.png'
import num3 from '../../assets/img/num3.png'
import drink_vector from '../../assets/img/drink_vector.png'
import drink2_vector from '../../assets/img/drink2_vector.png'
import photo_vector from '../../assets/img/photo_vector.png'
import styled from "styled-components";

const Konkurs: FC = () => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [photo, setPhoto] = useState('')
    const [photoError, setPhotoError] = useState('')
    const [agreement, setAgreement] = useState(false)
    const [agreementError, setAgreementError] = useState('')

    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState(false)

    return (
        <div>
            <div className="mt-6 w-full">
                <img src={trophy} className="w-full" alt="" />
                <div className="text-center uppercase text-4xl font-bold festivo">
                    Konkurs
                </div>
            </div>
            <div className="mt-4 px-4">
                <div className="rounded-3xl">
                    <div className="relative rounded-8 overflow-hidden">
                        <img src={woman_drink} alt="woman" />
                        <div className="absolute left-0 bottom-0 z-10 w-full h-1/2 bg-gradient-to-t from-black to-gransparent text-white text-4xl font-semibold flex items-end justify-center text-center uppercase festivo px-5">
                            MIXUJ PO SWOJEMU <br /> I WYGRAJ
                        </div>
                    </div>
                    <div className="px-5 pb-3 pt-3 text-white font-medium bg-black rounded-b-3xl">
                        Zainspiruj się przepisami na drinki ze Schweppes
                        i namieszaj po swojemu w konkursie!
                    </div>
                </div>
            </div>
            <div className="mt-12 px-4">
                <p className="text-2xl font-semibold festivo uppercase text-center">Nagrody</p>
                <div className="w-full relative ">
                    <img src={awards} alt="" className="w-full" />

                </div>
                <div className="relative">
                    <p className="text-center text-lg font-bold">
                        2x Głośnik JBL Go
                    </p>
                    <p className="text-center text-lg font-bold">
                        2x Głośnik JBL Flip
                    </p>
                    <p className="text-center text-lg font-bold">
                        10x zestaw Schweppes
                    </p>
                    <img src={awards_bubbles} className="absolute left-0 top-0 z-0" />
                </div>
            </div>

            <div className="my-10 px-4">
                <p className="text-2xl font-semibold festivo uppercase text-center">Zasady</p>
                <div className="mt-7">
                    <div className="mx-auto relative w-24">
                        <img src={drink_vector} alt="" className="" />
                        <img src={num1} alt="" className="absolute -left-28 top-1/2 transform -translate-y-1/2 w-16" />
                    </div>
                    <p className="font-semibold text-lg text-center mt-4">
                        Zmiksuj autorskiego drinka na bazie dowolnego
                        Schweppes’a i ulubionych dodatków.

                    </p>
                </div>
                <div className="mt-7">
                    <div className="mx-auto relative w-24">
                        <img src={drink2_vector} alt="" className="" />
                        <img src={num2} alt="" className="absolute -left-28 top-1/2 transform -translate-y-1/2 w-16" />
                    </div>
                    <p className="font-semibold text-lg text-center mt-4">
                        Możesz go zrobić: w ekstrawaganckim szkle,
                        z oryginalnym przystrojeniem, na ciekawym tle. Byle po swojemu!
                    </p>
                </div>
                <div className="mt-7">
                    <div className="mx-auto relative w-32">
                        <img src={photo_vector} alt="" className="" />
                        <img src={num3} alt="" className="absolute -left-24 top-1/2 transform -translate-y-1/2 w-16" />
                    </div>
                    <p className="font-semibold text-lg text-center mt-4">
                        Zrób fotkę drinka i prześlij!
                    </p>
                </div>

            </div>

            {!submitted && <form onSubmit={async e => {
                e.preventDefault()
                setLoading(true)
                let hasErrors = false
                setEmailError('')
                setPhotoError('')
                setAgreementError('')
                const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!email) {
                    setEmailError("Wpisz adres email")
                    hasErrors = true
                } else if (!regex.test(email)) {
                    setEmailError("Niepoprawny adres email")
                    hasErrors = true
                }
                if (!agreement) {
                    setAgreementError('Zaznacz zgodę')
                    hasErrors = true
                }
                if (!photo) {
                    setPhotoError('Dodaj zdjęcie konkursowe')
                    hasErrors = true
                }
                if (hasErrors) {
                    setLoading(false)
                    return
                }
                try {
                    const payload = {
                        email: email,
                        image: photo,
                        term1: agreement,
                        term2: 1,
                        term3: 0,
                        edition: 1
                    }
                    const data = await fetch('https://api.schweppespolska.pl/save', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload)
                    })
                    const json = await data.json()
                    console.log(json)
                    if (json.code === 200) {
                        setSubmitted(true)
                    } else setSubmitError(true)
                } catch (error: any) {
                    const err = { ...error }
                    console.log(err)
                }
                setLoading(false)
            }} className="w-full px-4">
                <p className="text-2xl font-semibold festivo uppercase text-center mt-10 mb-5">Wez udzial</p>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="bg-white px-5 py-4 mb-3 rounded-2xl w-full placeholder-black font-semibold focus:outline-none text-lg" placeholder="Adres e-mail" />
                {emailError && <span className="text-red-600 font-bold text-sm">{emailError}</span>}
                <FileInput setImage={setPhoto} />
                {photoError && <span className="text-red-600 font-bold text-sm">{photoError}</span>}
                <div className="mt-5">
                    <Checkbox checked={agreement} setChecked={setAgreement} />
                    {agreementError && <span className="text-red-600 font-bold text-sm">{agreementError}</span>}
                </div>
                <div className="mt-5">
                    <SubmitButton type="submit" className="w-full pt-5 pb-5  mt-2 rounded-2xl text-center font-bold text-3xl block stripes-dark festivo" disabled={loading}>Wyslij zgłoszenie</SubmitButton>
                </div>
                {submitError && <span className="text-red-600 font-bold text-sm">Wystąpił błąd spróbuj ponownie</span>}
            </form>}
            {
                submitted && <div className="text-center uppercase text-4xl font-bold festivo px-6">
                    Twoje zgłoszenie zostało zapisane!
                </div>
            }
            <p className="font-semibold text-lg text-center mt-4">
                Konkurs trwa do 30.06.2022 r.
            </p>
        </div >
    )
}

export default Konkurs

const SubmitButton = styled.button`
    transition: all .2s;

    &:disabled{
        filter: grayscale(.8);
    }
`