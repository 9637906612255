import { FC } from "react"

import Button from "../../components/button/button"

import drinks from '../../assets/img/drink_circles.png'
import trophy from '../../assets/img/trophy_circles.png'
import bottle from '../../assets/img/bottle_circles.png'
import logo from '../../assets/img/logo.png'
import circle_black from '../../assets/img/circle_black.png'

const Homepage: FC = () => {
    return (
        <div>
            <div className="-mt-16 mb-8 relative">
                <div className="w-44 mx-auto relative z-40">
                    <img src={logo} alt="schweppes" className="w-full" />
                </div>

                <img src={circle_black} alt="" className="w-8 absolute right-4 bottom-5" />
                <div className="w-8 h-8 border border-black rounded-full absolute top-4 left-8"></div>
            </div>
            <div>
                <div className="w-full relative">
                    <p className="block text-7xl text-center font-bold uppercase festivo -ml-8">Miksuj</p>
                    <p className="block text-7xl text-center font-bold uppercase festivo">Jak</p>
                    <p className="block text-7xl text-center font-bold uppercase festivo -mr-5"><span className="text-stripes festivo">lubisz</span></p>

                    <div className="w-2 h-2 bg-black rounded-full absolute left-8 top-8"></div>
                    <div className="w-3 h-3 bg-black rounded-full absolute right-5 bottom-3"></div>
                    <div className="w-4 h-4 border border-black rounded-full absolute right-3 bottom-1"></div>
                </div>
                <div className="w-full mt-12">
                    <img src={drinks} alt="drink_circles" />
                    <div className="mt-4 px-4 font-medium leading-snug" >
                        Chcesz rozkręcić kubki smakowe swoich gości? Zainspiruj się przepisami na drinki ze Schweppes i zmixuj coś niesamowitego.
                        <div className="mt-5">
                            <Button link="/przepisy" stripes="stripes-dark" text="Odkryj przepisy" />
                        </div>
                    </div>
                </div>
                <div className="w-full mt-16">
                    <img src={trophy} alt="drink_circles" />
                    <div className="mt-4 px-4 font-medium leading-snug">
                        Masz ochotę nieźle namieszać, a przy okazji zawalczyć o ciekawe nagrody? Koniecznie sprawdź nasze konkursy!
                        <div className="mt-5">
                            <Button link="/konkurs" stripes="stripes-light" text="Weź udział w konkursie" novelty />
                        </div>
                    </div>
                </div>
                <div className="w-full mt-16 mb-10">
                    <img src={bottle} alt="drink_circles" />
                    <div className="mt-4 px-4 font-medium leading-snug">
                        Poznaj całą gamę wyjątkowych smaków Schweppes. Porównuj je ze sobą, eksperymentuj, mieszaj z ulubionymi dodatkami i… smakuj życie po swojemu.
                        <div className="mt-5">
                            <Button link="/smaki" stripes="stripes-dark" text="Odkryj smaki" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage