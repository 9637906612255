import { FC, useEffect, useState } from "react";

const FileInput: FC<{ setImage: (arg: string) => void }> = ({ setImage }) => {
    const [file, setFile] = useState(null)
    const [fileError, setFileError] = useState('')
    const [preview, setPreview] = useState('')

    const toBase64 = (file: any, cb: any) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(reader.result)
        }
        reader.onerror = function (error) {
            console.log(error)
        }
    }

    const saveFile = (e: any) => {
        if (!e || e.length !== 1) return
        const file = e[0]
        if (file.size >= 8388608) {
            setFileError('Zbyt duży plik')
            return
        }
        setFileError('')
        setFile(file)
        const preview = URL.createObjectURL(file)
        setPreview(preview)
        toBase64(file, (result: string) => {
            console.log(result)
            setImage(result)
        })
    }

    const removeImg = () => {
        setPreview('')
        setImage('')
        setFile(null)
    }

    return (
        <div>
            {!preview && <div>
                <input type="file" accept="image/*" className="hidden" id="file" onChange={e => saveFile(e.target.files)} multiple={false} />
                <label htmlFor="file" className={`w-full pt-5 pb-5  mt-2 rounded-2xl text-center font-bold text-4xl block stripes-light festivo cursor-pointer`}>Dodaj zdjecie</label>
                <p className="text-center text-lg">maks. rozmiar zdjęcia 8 mb</p>
                {fileError && <span className="text-red-600 font-bold text-sm">{fileError}</span>}
            </div>}
            {preview && <div className="mt-4 w-full">
                <img src={preview} alt="" className="max-w-full mx-auto" />
                <div>
                    <button className={`w-full pt-5 pb-5  mt-2 rounded-2xl text-center font-bold text-4xl block stripes-light festivo cursor-pointer`} onClick={removeImg}>Usuń</button>
                </div>
            </div>}
        </div>
    )
}

export default FileInput