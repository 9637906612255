import { FC } from "react";

const Checkbox: FC<{ setChecked: (arg: boolean) => void, checked: boolean }> = ({ checked, setChecked }) => {
    return (
        <div className="flex items-start font-medium">
            <input type="checkbox" className="checkbox hidden" id="chck" onChange={() => setChecked(!checked)} />
            <label className="w-7 h-7 rounded-full bg-dark_yellow p-1.5 block flex-shrink-0 mr-2 cursor-pointer" htmlFor="chck">
                <div className="w-full h-full rounded-full "></div>
            </label>
            <div>
                Zapoznałem (am) się z <a href="/regulamin.pdf" className="cursor-pointer" target={'_blank'}>Regulaminem</a> oraz wyrażam zgodę na przestrzeganie jego postanowień,
            </div>
        </div>
    )
}

export default Checkbox